import { mapActions, mapGetters } from 'vuex'
import { BTooltip } from 'bootstrap-vue';
import VueLoader from '../../../components/layout/Loader'
import ProjectJoinButton from '../project/projectJoinButton.vue'
import PhotoObservationCard from "../../../components/layout/PhotoObservationCard"
import PhotoProjectCard from "../../../components/layout/PhotoProjectCard";

export default {

  components: {
    BTooltip,
    VueLoader,
    ProjectJoinButton,
    PhotoObservationCard,
    PhotoProjectCard,
  },

  provide() {
    return { parentValidator: this.$validator }
  },

  data() {
    return {
      headerData: {
        backgroundPath: '/assets/images/header/Home_Image_1.jpg',
        picturePath: '',
        headerTitle: 'Your free participatory science platform',
        subHeaderTitle: 'Create projects, build datasheets, add data and view results in real-time',
        showHomepageButtons: true,
      },

      observationCardArr: [],

      testimonialsCards: [
        {
          id: 1,
          author: {
            firstName: 'Kate',
            lastName: 'Rinder',
            photo: '/assets/images/testimonials/KateRinder.png'
          },
          location: 'Youth Leadership Coordinator - North Cascades Institute',
          text: "I've looked at a number of different platforms and none of them have had the right tools and capacity for what we want to do. I'm excited that CitSci does!"
        },
        {
          id: 2,
          author: {
            firstName: 'Jorge',
            lastName: 'Figueroa',
            photo: '/assets/images/testimonials/JorgeFigueroa.png'
          },
          location: 'Principal and Director - El Laboratorio, Boulder CO',
          text: "CitSci is best-in-class. A powerful online platform for communities and community scientists to elegantly collect, manage, and analyze data with scientific integrity."
        },
        {
          id: 3,
          author: {
            firstName: 'Hope',
            lastName: 'Braithwaite',
            photo: '/assets/images/testimonials/HopeBraithwaite.png'
          },
          location: 'Water Quality Program Coordinator - Utah Water Watch',
          text: "I'm not sure what I would do without CitSci. They make coordinating projects a breeze. I can manage my sites and assign volunteers all in the same place."
        }
      ],
      baseURL: "",
    }

  },

  created() {
    this.reset()
    this.sendDataToHeader(this.headerData, this)
    this.$emit('is-homepage', true)
    //baseURL
  },

  mounted() {

    // Top 3 projects with most members are fetched, use results from project list store if present to prevent additional server calls
    if (!this.projects || this.projects.length === 0) {
      this.baseURL = window.location.origin;
      if (this.baseURL==='https://citsci.org') {
        // New list since March 2025 <LNT University Challenge, UWW, Extremophile >
        this.getFeaturedProjects({ urlParams: '?id[]=01953e13-c4a3-7067-95f4-3f27a173451a&id[]=3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c&id[]=f09a68fd-a085-4d6b-890a-5dbc6b440604' })
      }
      else {
        // for alpha and beta <UWW, BEW, TLI Sainfoin>
        this.getFeaturedProjects({ urlParams: '?id[]=9724a020-3394-45f2-ad8f-202f9c2529de&id[]=3e2b9d9c-5d3f-4bc7-83a8-f303904ead8c&id[]=89b16d94-1f67-4eaa-823b-aae65aa05181' })
      }
    }

    // Fetch the 4 most recent observations with images
    this.observationCardArr = []
    this.getAllObservationsList({ urlParams: '?order[observedAt]=desc&records.recordType=image&itemsPerPage=4' })
    this.getStats()
  },

  computed: {
    ...mapGetters({
      error: 'project/show/error',
      violations: 'project/show/violations',
      isLoadingStats: 'about/show/isLoading',
      stats: 'about/show/stats',
      observationCards: 'observation/list/allItems',
      isLoadingObservations: 'observation/list/isLoadingAll',
      featuredProjects: 'project/list/featuredProjects',
      isLoadingProjects: 'project/list/isLoading',
    }),

  },

  methods: {
    ...mapActions({
      getStats: 'about/show/getStats',
      reset: 'about/show/reset',
      getFeaturedProjects: 'project/list/getFeaturedProjects',
      getAllObservationsList: 'observation/list/getAllObservationsList',
      joinToProject: 'project/show/joinToProject',
    }),

    addThousandsPoint(number) {
      // Fixes browser debugger error when number is undefined
      if (number == null)
        number = 0;

      number = number.toString()
      while (true) {
        var n2 = number.replace(/(\d)(\d{3})($|,|\.)/g, '$1,$2$3')
        if (number === n2) break
        number = n2
      }
      return number
    },

    onCardClick(evt, projectData) {
      evt.preventDefault();

      if (evt.target.getAttribute('class').indexOf('btn-join') !== -1) {
        if (JSON.parse(localStorage.getItem('sessionData')) == null) {
          this.$root.$emit('bv::show::modal', 'not-logged-in-modal');
          return;
        } else {
          this.joinToProject({ id: projectData.id });
          return;
        }
      }

      this.$router.push('/projects/' + projectData.urlField);
    },

    mouseOver() {
      // this.$refs.buttonTooltip1.$emit('open')
    }
  },

  watch: {
    violations(newViolationsVal) {
      if (typeof newViolationsVal == 'object' && newViolationsVal != null && newViolationsVal._error.length > 0) {
        delete newViolationsVal._error
      }
      return newViolationsVal
    },
  },

  beforeDestroy() {
    this.reset()
  }
}
